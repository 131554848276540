
import InputText from 'primevue/inputtext'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import {
  getCurrentInstance,
  onMounted,
  ref,
  toRefs
} from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'
import { Form, Field, FieldArray, ErrorMessage, useForm } from 'vee-validate'
import { isNull } from 'lodash'
import Checkbox from 'primevue/checkbox'
import * as Yup from 'yup'

export default {
  components: {
    Dialog,
    // Form,
    // Field,
    // FieldArray,
    Checkbox,
    InputText,
    Button
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    ritData: {
      default: null as any
    }
  },
  emits: ['hideScheduleForm', 'onSubmit'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const isLoading = ref(true)
    const { show, ritData } = toRefs(props)
    const showModal = show.value
    const dataSource = ref([]) as any
    const dataSourceTemp = ref([]) as any
    const dataLatestRevision = ref() as any
    const dataForm = ref([]) as any
    const isCrossdayValue = ref(false)

    const endpoint = '/management/v1/MsRoute'

    const getDetail = async () => {
      store.dispatch('showLoading')
      dataSourceTemp.value = []
      await apiUseCase.get(`${endpoint}/${ritData.value.ritId}`).then((response) => {
        if (!response.error) {
          console.log('response', response)
          // eslint-disable-next-line array-callback-return
          response.result.Terminal.map((data: any) => {
            const obj = {
              terminalId: data.MsStationId,
              name: data.Name,
              cityName: data.CityName,
              departure: null,
              arrival: null,
              routeDetailId: data.MsRouteDetailId
            }
            dataSourceTemp.value.push(obj)
          })
          console.log('dataSource', dataSourceTemp.value)
        }
      })
      dataSource.value = ritData?.value.data?.detailTerminals.length > 0 ? ritData?.value.data?.detailTerminals : dataSourceTemp.value
      isCrossdayValue.value = ritData?.value?.data?.isCrossDay
      isLoading.value = false
      store.dispatch('hideLoading')
    }

    const closeDialog = () => {
      emit('hideScheduleForm')
    }

    const checkdataFormAfterSubmit = (datas: any) => {
      datas.forEach((data: any, index: any) => {
        if ((index === 1 && !data.arrival) || (index === index.length - 1 && !data.departure) || !data.departure || !data.arrival) {
          $toast.add({
            severity: 'error',
            summary: 'Gagal',
            detail: 'Silahkan lengkapi jadwal terlebih dahulu',
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      })
    }

    const checkPayload = (datas: any) => {
      let val = true
      // eslint-disable-next-line consistent-return
      datas.forEach((data: any, index: any) => {
        if (val) {
          if ((index > 0 && !data.arrival) || (index < index.length - 2 && !data.departure)) {
            console.log('xxx')
            $toast.add({
              severity: 'error',
              summary: 'Gagal',
              detail: 'Silahkan lengkapi jadwal terlebih dahulu',
              group: 'bc',
              closable: false,
              life: 3000
            })
            val = false
          }
          // console.log('aaa', isCrossdayValue.value)
          if (!isCrossdayValue.value) {
            // eslint-disable-next-line no-mixed-operators
            if (index > 0 && (((datas[index].arrival <= datas[index - 1].departure) || (data.departure <= data.arrival)))) {
              console.log('yyy')
              $toast.add({
                severity: 'error',
                summary: 'Gagal',
                detail: 'Jadwal tidak valid. Mohon untuk periksa jadwal kembali',
                group: 'bc',
                closable: false,
                life: 3000
              })
              val = false
            }
          }
        }
        console.log('val', val)
      })
      return val
    }

    const onSubmitAct = () => {
      console.log('sbumit', dataSource.value)
      store.dispatch('showLoading')
      let isValid = true
      // console.log('isValid', checkPayload(dataSource.value))
      isValid = checkPayload(dataSource.value)
      if (isValid) {
        const obj = {
          data: dataSource.value,
          // ...ritData.value
          busOrder: ritData.value.busOrder,
          ppOrder: ritData.value.ppOrder,
          ritOrder: ritData.value.ritOrder,
          isCrossDay: isCrossdayValue.value
        }
        emit('onSubmit', obj)
      }
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      console.log('rit', ritData.value)
      getDetail()
    })

    return {
      isLoading,
      showModal,
      closeDialog,
      dataSource,
      onSubmitAct,
      isCrossdayValue,
    }
  }
}
