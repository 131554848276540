<template>
  <div class="card-custom">
    <div class="ctnt">
      <slot name="header" v-if="slot['header']" />
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { useSlots } from 'vue'

const slot = useSlots()
</script>
