
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import InlineSvg from 'vue-inline-svg'
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Button from 'primevue/button'
import CardCustom from '@/views/components/card/Custom.vue'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import { Form, Field, FieldArray } from 'vee-validate'
import { DatePicker } from 'v-calendar'
import rgxExp from '@/utils/helpers/regExp'
import * as Yup from 'yup'
import moment from 'moment'
import $ from 'jquery'
import apiUseCase from '@/usecase/apiUseCase'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import RowInput from '../components/RowInput.vue'

export default defineComponent({
  name: 'TrayekForm',
  components: {
    InlineSvg,
    InputText,
    RadioButton,
    Button,
    CardCustom,
    SelectFetch,
    Form,
    Field,
    FieldArray,
    DatePicker,
    RowInput
  },
  props: {
    trayekType: {
      type: String,
      default: () => ''
    }
  },
  setup() {
    const endpointTrayek = 'management/v1/Trayek'
    const valueM = ref({ value: '61c57f5d-4a36-4ff9-5c79-08dbcaff233f', label: 'PATAS' })
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isSubmitted = ref(false)
    const trayekType = ref(0)
    const idPrams = ref(route.params.id)

    const formValues = reactive({
      Name: store.state.trayek.form.step1.name ?? '',
      Code: store.state.trayek.form.step1.code ?? '',
      Class: store.state.trayek.form.step1.class ?? null,
      StartDate: store.state.trayek.form.step1.startDate ?? '',
      EndDate: store.state.trayek.form.step1.endDate ?? '',
      IsTol: store.state.trayek.form.step1.isTol ?? false,
      Rits: store.state.trayek.form.step1.rits
    })

    // const schemaFormTrayek = Yup.object().shape({
    //   Name: Yup.string()
    //     .required('Nama Trayek tidak boleh kosong')
    //     .max(50, 'Nama Trayek tidak boleh melebihi 50 karakter'),
    //   Code: Yup.string()
    //     .required('Kode Trayek tidak boleh kosong')
    //     .trim()
    //     .max(20, 'Kode Trayek tidak boleh melebihi 20 karakter'),
    //   Class: Yup.object().required('Kelas tidak boleh kosong'),
    //   StartDate: Yup.string().required('Tanggal mulai tidak boleh kosong'),
    //   EndDate: Yup.string().required('Tanggal berakhir tidak boleh kosong'),
    //   Rits: Yup.array().of(
    //     Yup.object().shape({
    //       rit: Yup.object()
    //     })
    //   )
    // })

    const schemaFormTrayek = Yup.object().shape({
      Name: Yup.string()
        .matches(rgxExp.notPrefixSpace, 'Nama Trayek tidak boleh hanya menggunakan spasi')
        .required('Nama Trayek tidak boleh kosong')
        .max(50, 'Nama Trayek tidak boleh melebihi 50 karakter'),
      Code: Yup.string()
        .matches(rgxExp.notPrefixSpace, 'Kode Trayek tidak boleh hanya menggunakan spasi')
        .required('Kode Trayek tidak boleh kosong')
        .matches(rgxExp.noWhiteSpaceAndSpecialChar, 'Kode Trayek hanya boleh terdiri dari huruf dan angka')
        .trim()
        .max(20, 'Kode Trayek tidak boleh melebihi 20 karakter')
        .test('unique-code', 'Kode Trayek sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpointTrayek}${returnUrlPrams({
              search: val,
              filterField: ['code']
            })}`)
            const filterResult = res.result.filter((x: any) => x.Code === val)
            if (idPrams.value) {
              return filterResult.length === 0 || val === formValues.Code
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      Class: Yup.object().required('Kelas tidak boleh kosong'),
      StartDate: Yup.string().required('Tanggal Mulai tidak boleh kosong'),
      EndDate: Yup.string().required('Tanggal Berakhir tidak boleh kosong'),
      Rits: Yup.array().of(
        Yup.object().notRequired()
      ).test('isindex0-filled', 'Salah satu Rit tidak boleh kosong', (val) => {
        if (!Array.isArray(val)) {
          return true
        }
        for (let i = 0; i < val.length; i++) {
          const temp: any = val[i]
          // console.log('zzzzzz', [i, val])
          if (i === 0 && !temp.rit) {
            return false
          }
        }
        return true
      }).test('is-unique', 'Rit tidak boleh ada yang sama', (val) => {
        console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        for (let i = 0; i < val.length; i++) {
          if (val[i]) {
            const temp: any = val[i]
            // console.log('xxx', temp.Id)
            // console.log('val', val[i])
            // console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
            if (set.has(temp.rit?.Id) && temp.rit) {
              // console.log('exist')
              return false
            }
            set.add(temp.rit?.Id)
          }
        }
        return true
      })
    })

    const schemaFormTrayekReserve = Yup.object().shape({
      Name: Yup.string()
        .matches(rgxExp.notPrefixSpace, 'Nama Trayek tidak boleh hanya menggunakan spasi')
        .max(50, 'Nama Trayek tidak boleh melebihi 50 karakter')
        .required('Nama Trayek tidak boleh kosong'),
      Code: Yup.string()
        .trim()
        .matches(rgxExp.noWhiteSpaceAndSpecialChar, 'Kode Trayek hanya boleh terdiri dari huruf dan angka')
        .matches(rgxExp.notPrefixSpace, 'Kode Trayek tidak boleh hanya menggunakan spasi')
        .required('Kode Trayek tidak boleh kosong')
        .max(20, 'Kode Trayek tidak boleh melebihi 20 karakter')
        .test('unique-code', 'Kode Trayek sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpointTrayek}${returnUrlPrams({
              search: val,
              filterField: ['code']
            })}`)
            const filterResult = res.result.filter((x: any) => x.Code === val)
            if (idPrams.value) {
              return filterResult.length === 0 || val === formValues.Code
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        })
        .required('Kode Trayek tidak boleh kosong'),
      Class: Yup.object().required('Kelas tidak boleh kosong'),
      // StartDate: Yup.string().required('Tanggal mulai tidak boleh kosong'),
      // EndDate: Yup.string().required('Tanggal berakhir tidak boleh kosong'),
      StartDate: Yup.string().required('Tanggal Mulai tidak boleh kosong'),
      EndDate: Yup.string().required('Tanggal Berakhir tidak boleh kosong'),
    })

    type TSchemaFormTrayek = Yup.InferType<typeof schemaFormTrayek>

    const isTol = ref(store.state.trayek.form.step1.isTol)

    const aaa = ref(null)

    const prevPage = () => {
      const temp = route.path.split('/')
      console.log(temp)
      router.push({
        name: temp[2]
      })
    }

    const nextPage = (val: any) => {
      isSubmitted.value = true
      console.log('values', val)
      const filterResult = val.Rits.filter((x: any) => x.rit !== null)
      console.log('filter', filterResult)
      val.Rits = filterResult
      val.IsTol = isTol.value
      console.log('values', val)
      store.dispatch('setTrayekForm1', val)
      store.dispatch('setTrayekFormStep', store.state.trayek.formStep + 1)
      console.log('form1', store.state.trayek.form.step1)
      console.log('formStep', store.state.trayek.formStep)
    }

    const onChange = () => {
      // console.log('val', aaa.value)
    }

    // const onSubmit = (val) => {
    //   console.log('submitt =>', val)
    // }

    const disableSpace = (val: any) => {
      console.log(val)
      if (val.keyCode === 32) {
        return val.preventDefault()
      }
      return true
    }

    onMounted(() => {
      console.log('asdasd', store.state)
      if (route.path.includes('main')) {
        trayekType.value = 0
      } else if (route.path.includes('airport')) {
        trayekType.value = 2
      } else {
        trayekType.value = 1
      }
    })

    return {
      nextPage,
      isTol,
      schemaFormTrayek,
      schemaFormTrayekReserve,
      formValues,
      isSubmitted,
      onChange,
      moment,
      prevPage,
      trayekType,
      disableSpace
    }
  }
})

