
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { Form, Field, FieldArray, ErrorMessage, useForm } from 'vee-validate'
import * as Yup from 'yup'
import InlineSvg from 'vue-inline-svg'
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Card from 'primevue/card'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import { DatePicker } from 'v-calendar'
import CardCustom from '@/views/components/card/Custom.vue'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import Checkbox from 'primevue/checkbox'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import moment from 'moment'
import apiUseCase from '@/usecase/apiUseCase'
import { useRoute } from 'vue-router'
import ScheduleForm from '../components/InputScheduleDialog.vue'

const module = 'globalSetting'

export default defineComponent({
  name: 'TrayekForm',
  components: {
    // InlineSvg,
    // Calendar,
    // InputText,
    // RadioButton,
    Button,
    Card,
    HjpTabView,
    ErrorMessage,
    // TabPanel,
    // CardCustom,
    SelectFetch,
    Form,
    Field,
    FieldArray,
    ScheduleForm
    // Checkbox,
    // DatePicker,
    // RowInput
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $icon,
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const inputSearch = ref('')
    const icon = $icon
    const activeTab = ref(0)
    const countForm = ref(9)
    const vehicleList = ref([])
    const selectedBus = ref(null) as any
    const logBus = ref() as any
    const logTrayek = ref() as any
    const time1 = ref()
    const isAnyBus = ref(false)
    const trayekType = ref(0)
    const step1Form = ref(store.state.trayek.form.step1)
    const initialValue = reactive({
      data: store.state.trayek.form.step2.data
    })
    const { setFieldValue, setValues } = useForm()
    const defaultValue = ref()
    const defaultDetailValue = ref() as any
    const showScheduleForm = ref(false)
    const selectedRit = ref(null) as any
    const globalValue = computed(() => store.getters[`${module}/maxPp`]?.Value)
    const isLoading = ref(true)
    const tabHeader = ref([
      { title: 'Log Bus', value: 0 },
      { title: 'Log Slot Trayek', value: 1 }
    ])
    const listBus = computed({
      get: () => store.state.trayek.listBus,
      set: (value) => {
        store.dispatch('setListBusTrayek', value)
      }
    })
    const listBusTemp = ref([]) as any
    const duplicateBus = ref([]) as any
    const currValues = ref(null) as any

    const vehicleEndpoint = '/management/v1/MsVehicle'
    const activityLogEndpoint = '/management/v1/ActivityLog'
    const trayekRouteEndpoint = '/management/v1/TrayekRoute'

    const schema = Yup.object().shape({
      data: Yup.array().of(
        Yup.object().shape({
          nopol: Yup.object().required('Bus tidak boleh kosong'),
          pps: Yup.array().of(
            Yup.object().shape({
              departure: Yup.string().when(['arrival'], ([arrival], sch) => (arrival && arrival.length > 0 ? sch.required('Jam Berangkat tidak boleh kosong') : sch.notRequired())),
              arrival: Yup.string().when(['departure'], ([departure], sch) => (departure && departure.length > 0 ? sch.required('Jam Kedatangan tidak boleh kosong') : sch.notRequired()))
            }, [['departure', 'arrival'], ['arrival', 'departure']]).test('is-unique', 'Jadwal belum lengkap', (val) => {
              console.log('details', val)
            })
          ).test('is-unique', 'Jam Keberangkatan tidak boleh ada yang sama', (val) => {
            // console.log('val', val)
            if (!Array.isArray(val)) {
              return true
            }
            const set = new Set()
            // console.log('set', set)
            for (let i = 0; i < val.length; i++) {
              if (val[i]) {
                // console.log(val[i])
                const temp: any = val[i]
                // console.log('xxx', temp.departure)
                // console.log('val', val[i])
                // console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
                if (set.has(temp.details[0]?.departure) && temp.details[0]?.departure) {
                  // console.log('exist')
                  return false
                }
                set.add(temp.details[0]?.departure)
              }
            }
            return true
          })
        })
      ).test('is-unique-time', 'Jam Keberangkatan tidak boleh ada yang sama', (val) => {
        // console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        // console.log('setX', set)
        for (let i = 0; i < val.length; i++) {
          let isExist = false
          if (val[i]) {
            const arrLong = val[i].pps?.length ?? 0
            // console.log('asdsadaX', val[i].pps)
            const pps = val[i].pps
            // console.log('pps', pps)
            if (pps !== undefined) {
              // eslint-disable-next-line array-callback-return, consistent-return
              pps.every((el: any) => {
                const temp: any = el.details[0]?.departure ?? null
                // console.log('aaaa', [temp, set, set.has(temp)])
                if (temp) {
                  if (set.has(temp)) {
                    // console.log('exist')
                    isExist = true
                    return false
                  }
                  isExist = false
                  set.add(temp)
                }
              })
            }
            if (isExist) {
              return !isExist
            }
          }
        }
        return true
      }).test('is-unique', 'Bus tidak boleh ada yang sama', (val) => {
        // console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        // console.log('set', set)
        for (let i = 0; i < val.length; i++) {
          if (val[i]) {
            // console.log(val[i])
            const temp: any = val[i]
            // console.log('xxx', temp.nopol?.Id)
            // console.log('val', val[i])
            console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
            if (set.has(temp.nopol?.Id) && temp.nopol) {
              // console.log('exist')
              return false
            }
            set.add(temp.nopol?.Id)
          }
        }
        return true
      })
    })

    const schemaAirport = Yup.object().shape({
      data: Yup.array().of(
        Yup.object().shape({
          nopol: Yup.object().required('Bus tidak boleh kosong'),
          pps: Yup.array().of(
            Yup.object().shape({
              // departure: Yup.string().when(['arrival'], ([arrival], sch) => (arrival && arrival.length > 0 ? sch.required('Jam Berangkat tidak boleh kosong') : sch.notRequired())),
              // arrival: Yup.string().when(['departure'], ([departure], sch) => (departure && departure.length > 0 ? sch.required('Jam Kedatangan tidak boleh kosong') : sch.notRequired())),
              // departure: Yup.string().required('Jam Berangkat tidak boleh kosong'),
              // arrival: Yup.string().required('Jam Kedatangan tidak boleh kosong'),
              details: Yup.array().of(
                Yup.object().shape({
                  // departure: Yup.string().when(['arrival'], ([arrival], sch) => (arrival && arrival.length > 0 ? sch.required('Jam Berangkat tidak boleh kosong') : sch.notRequired())),
                  // arrival: Yup.string().when(['departure'], ([departure], sch) => (departure && departure.length > 0 ? sch.required('Jam Kedatangan tidak boleh kosong') : sch.notRequired())),
                  departure: Yup.string().required('Jadwal Rit tidak boleh kosong'),
                  arrival: Yup.string().required('Jadwal Rit tidak boleh kosong'),
                }, [['departure', 'arrival'], ['arrival', 'departure']])
              )
            }, [['departure', 'arrival'], ['arrival', 'departure']])
          ).test('is-unique', 'Jam Keberangkatan tidak boleh ada yang sama', (val) => {
            // console.log('val', val)
            if (!Array.isArray(val)) {
              return true
            }
            const set = new Set()
            // console.log('set', set)
            for (let i = 0; i < val.length; i++) {
              if (val[i]) {
                // console.log(val[i])
                const temp: any = val[i]
                // console.log('xxx', temp.departure)
                // console.log('val', val[i])
                // console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
                if (set.has(temp.details[0]?.departure) && temp.details[0]?.departure) {
                  // console.log('exist')
                  return false
                }
                set.add(temp.details[0]?.departure)
              }
            }
            return true
          })
        })
      ).test('is-unique', 'Bus tidak boleh ada yang sama', (val) => {
        // console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        // console.log('set', set)
        for (let i = 0; i < val.length; i++) {
          if (val[i]) {
            // console.log(val[i])
            const temp: any = val[i]
            // console.log('xxx', temp.nopol?.Id)
            // console.log('val', val[i])
            console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
            if (set.has(temp.nopol?.Id) && temp.nopol) {
              // console.log('exist')
              return false
            }
            set.add(temp.nopol?.Id)
          }
        }
        return true
      })
    })

    const schemaReserve = Yup.object().shape({
      data: Yup.array().of(
        Yup.object().shape({
          nopol: Yup.object().required('Bus tidak boleh kosong'),
        })
      ).test('is-unique', 'Bus tidak boleh ada yang sama', (val) => {
        console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        console.log('set', set)
        for (let i = 0; i < val.length; i++) {
          if (val[i]) {
            console.log(val[i])
            const temp: any = val[i]
            console.log('xxx', temp.nopol?.Id)
            // console.log('val', val[i])
            // console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
            if (set.has(temp.nopol?.Id) && temp.nopol) {
              // console.log('exist')
              return false
            }
            set.add(temp.nopol?.Id)
          }
        }
        return true
      })
    })

    const setDefaultDetails = () => {
      defaultDetailValue.value = null
      const detailTemp = [] as any
      for (let index = 0; index < Number(step1Form.value.rits.length ?? 0); index++) {
        const obj = {
          departure: null,
          arrival: null,
          isCrossDay: false,
          detailTerminals: [] as any
        }
        detailTemp.push(obj)
      }
      defaultDetailValue.value = {
        id: null,
        departure: null,
        arrival: null,
        isCrossDay: false,
        details: detailTemp
      }
      // console.log('defaultDetail', defaultDetailValue.value)
    }

    const setInitialValue = () => {
      const temp = []
      const detailTemp = [] as any
      const time = new Date()
      // if (trayekType.value === 2) {
      for (let index = 0; index < Number(step1Form.value.rits.length ?? 0); index++) {
        // detailTemp.push(
        //   {
        //     // id: null,
        //     departure: null,
        //     arrival: null,
        //     isCrossDay: false,
        //     detailTerminals: [] as any
        //   }
        // )
        const obj = {
          // id: null,
          departure: null,
          arrival: null,
          isCrossDay: false,
          detailTerminals: [] as any
        }

        // step1Form.value.rits[index].rit.Terminal.forEach((data: any) => {
        //   const objdetail = {
        //     ...data,
        //     departure: null,
        //     arrival: null,
        //     isCrossDay: false,
        //     MsRouteId: step1Form.value.rits[index].Id ?? null
        //   }
        //   obj.detailTerminals.push(objdetail)
        // })
        detailTemp.push(obj)
      }
      for (let index = 0; index < 1; index++) {
        temp.push(
          {
            id: null,
            departure: null,
            arrival: null,
            isCrossDay: false,
            details: detailTemp
          }
        )
      }
      // } else {
      //   for (let index = 0; index < 1; index++) {
      //     temp.push({ id: null, departure: null, arrival: null })
      //   }
      // }
      if (store.state.trayek.form.step2.data.length === 0) {
        initialValue.data.push({
          nopol: null,
          pps: temp
        })
      }
      if (!defaultValue.value) {
        defaultValue.value = {
          nopol: null,
          pps: temp
        }
      }
      // if (trayekType.value === 0) {
      // // } else {
      // //   // eslint-disable-next-line no-lonely-if
      // //   if (!defaultValue.value) {
      // //     defaultValue.value = {
      // //       nopol: null
      // //     }
      // //   }
      // }
      // console.log('init', initialValue)
      isLoading.value = false
    }

    const addForm = () => {
      isLoading.value = true
      setInitialValue()
      isLoading.value = false
    }

    const nextPage = (val: any) => {
      console.log('val', val)
      try {
        listBus.value = listBusTemp.value
        for (let index = 0; index < val.data.length; index++) {
          for (let x = 0; x < val.data[index].pps.length; x++) {
            val.data[index].pps[x].departure = val.data[index].pps[x].details[0].departure
            val.data[index].pps[x].arrival = val.data[index].pps[x].details[val.data[index].pps[x].details.length - 1].arrival
            for (let i = 0; i < store.state.trayek.form.step1.rits.length; i++) {
              if (val.data[index].pps[x].details) {
                if (val.data[index].pps[x].details[i].departure && val.data[index].pps[x].details[i].arrival) {
                  val.data[index].pps[x].details[i].routeId = store.state.trayek.form.step1.rits[i].rit.Id
                } else {
                  val.data[index].pps[x].details[i].routeId = null
                }
                val.data[index].pps[x].isCrossDay = val.data[index].pps[x].details.filter((e: any) => e.isCrossDay).length > 0
              }
            }
          }
        }
        store.dispatch('setTrayekForm2', val.data)
        store.dispatch('setTrayekFormStep', store.state.trayek.formStep + 1)
        console.log('form', store.state.trayek.form)
        // console.log('form2', store.state.trayek.form.step2)
        console.log('formStep', store.state.trayek.formStep)
      } catch (error) {
        console.log('error', error)
        $toast.add({
          severity: 'error',
          summary: 'Gagal',
          detail: 'Silahkan lengkapi jadwal terlebih dahulu',
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
    }

    const prevPage = () => {
      if (!route.params.id) {
        store.dispatch('setTrayekForm2', [])
      }
      store.dispatch('setTrayekFormStep', store.state.trayek.formStep - 1)
      // console.log('formStep', store.state.trayek.formStep)
    }

    const onSubmit = () => {
      console.log('submit', initialValue)
    }

    const getBusLog = async () => {
      // console.log('selectedBus', selectedBus.value)
      await apiUseCase.get(`${activityLogEndpoint}/VehicleLogs?nopol=${selectedBus.value.Nopol}`).then((response) => {
        // console.log('res', response)
        logBus.value = response.result.slice(0, 5)
        // console.log('dataX', logBus.value)
      })
    }

    const getTrayekLog = async () => {
      await apiUseCase.get(`${activityLogEndpoint}/TrayekLogs?trayekId=${route.params.id}`).then((response) => {
        // console.log('res', response)
        logTrayek.value = response.result.slice(0, 5)
        // console.log('dataX', logTrayek.value)
      })
    }

    const setCurrVehicleInfo = (val: any) => {
      // console.log('aaaXXX', val)
      selectedBus.value = val
      // console.log('selectedBus', selectedBus.value)
      if (selectedBus.value) {
        getBusLog()
      }
    }

    const showForm = () => {
      // isLoading.value = true
      // if (initialValue.data.length === 0) {
      //   setInitialValue()
      // }
      isAnyBus.value = true
      // isLoading.value = false
    }

    const focus = (val: any) => {
      // console.log('val', [val, document.getElementById('class-input'), initialValue])
      selectedBus.value = val
      console.log(selectedBus)
      if (selectedBus.value) {
        getBusLog()
      }
    }

    const tabsChange = (val: any) => {
      // console.log('tabchange', val)
      activeTab.value = val
      if (val === 1 && !logTrayek.value) {
        // console.log('zzzzz')
        getTrayekLog()
      }
    }

    const changeBus = (val: any, index: any) => {
      // console.log('changeBus', [val, index])
      let prevVal = null
      if (val) {
        prevVal = listBusTemp.value[index]
        listBusTemp.value.splice(index, 1)
        listBusTemp.value[index] = val ? val.Id : null
        // console.log('listBusTemp', listBusTemp.value)
        if (listBusTemp.value.length > 0) {
          if (listBusTemp.value.includes(val.Id)) {
            const collect = []
            const collectPrev = []
            let count = 0
            let countPrev = 0
            for (let i = 0; i < listBusTemp.value.length; i++) {
              if (listBusTemp.value[i] === val.Id) {
                count += 1
                collect.push(i)
              }
              if (listBusTemp.value[i] === prevVal) {
                countPrev += 1
                collectPrev.push(i)
              }
            }
            // console.log('collectX', [collect, collectPrev])
            if (count > 1 && countPrev > 1) {
              // console.log('aaaa')
              duplicateBus.value = collect.concat(collectPrev)
            } else if (countPrev > 1 && count < 1) {
              // console.log('bbbb')
              duplicateBus.value = collectPrev
            } else if (count > 1 && countPrev < 1) {
              // console.log('cccc')
              duplicateBus.value = collect
            } else {
              duplicateBus.value = []
            }
            // const collect = listBusTemp.value.findIndex((x: any) => x === val.Id)
            // console.log('collect', duplicateBus.value)
          }
        }
      } else {
        prevVal = listBusTemp.value[index]
        // console.log('prevVal', prevVal)
        const track = duplicateBus.value.indexOf(index)
        // console.log('track', track)
        if (track) {
          listBusTemp.value.splice(index, 1)
          // duplicateBus.value.splice(track, 1)
        }
        const collectPrev = []
        let countPrev = 0
        for (let i = 0; i < listBusTemp.value.length; i++) {
          if (listBusTemp.value[i] === prevVal) {
            countPrev += 1
            collectPrev.push(i)
          }
        }
        // console.log('collectX', [listBusTemp.value, collectPrev])
        if (countPrev > 1) {
          // console.log('bbbb')
          duplicateBus.value = collectPrev
        } else {
          duplicateBus.value = []
        }
        // console.log('collectX', duplicateBus.value)
      }
    }

    const showDuplicateMessage = (val: any) => {
      if (duplicateBus.value.includes(val)) {
        return true
      }
      return false
    }

    const checkIsDate = (val: any) => Date.parse(val)

    const getTimeOnly = (val: any) => {
      // console.log('time', val)
      if (checkIsDate(val)) {
        const x = moment(val).format('HH:mm')
        // console.log('x', x)
        return x
      }
      return val
    }

    const getVal = (idx: any, id: any) => {
      const x = document.getElementById(`departure-${idx}-${id}`)
      // console.log('x', x)
    }

    const showScheduleFormAct = (formdata: any, currData: any, data: any, busdata: any, busorder: any, pporder: any, ritorder: any) => {
      console.log('id', [formdata, currData, data, busdata, busorder, pporder, ritorder])
      currValues.value = formdata
      selectedRit.value = {
        ritId: data.Id,
        ritName: data.Name,
        busName: busdata.Nopol ?? '-',
        busOrder: busorder,
        ppOrder: pporder,
        ritOrder: ritorder,
        data: currData
      }
      showScheduleForm.value = true
    }

    const hideScheduleFormAct = () => {
      showScheduleForm.value = false
      selectedRit.value = null
    }

    const setScheduleValue = (dataX: any) => {
      // console.log('dataForm', currValues.value)
      // console.log('dataX', dataX)
      // console.log('data', dataX.data[0].departure)
      // console.log('isArray', Array.isArray(dataX.data))
      // console.log('now', currValues.value.data[dataX.busOrder].pps[dataX.ppOrder].details)
      if (currValues.value.data[dataX.busOrder].pps[dataX.ppOrder].details.length > 0) {
        const x = currValues.value.data[dataX.busOrder].pps[dataX.ppOrder].details
        const objMain = {
          departure: dataX.data[0]?.departure,
          arrival: dataX.data[dataX.data.length - 1].arrival,
          isCrossDay: dataX.isCrossDay,
          detailTerminals: [],
        }
        x[dataX.ritOrder] = objMain
        dataX.data.forEach((d: any) => {
          const obj = {
            id: d.id ?? null,
            name: d.name ?? null,
            departure: d.departure ?? null,
            arrival: d.arrival ?? null,
            isCrossDay: dataX.isCrossDay ?? false,
            routeDetailId: d.routeDetailId
          }
          x[dataX.ritOrder].detailTerminals.push(obj)
        })
        if (dataX.ppOrder === 0) {
          setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].departure`, 'xxx')
        }
        // setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].departure`, 'xxx')
        // setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].arrival`, 'xxx')
        setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].arrival`, dataX.data[dataX.ppOrder]?.arrival)
        setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].details[${dataX.ritOrder}]`, x)
      } else {
        const x = currValues.value.data[dataX.busOrder].pps[dataX.ppOrder].details
        const object = {
          departure: dataX.data[0]?.departure,
          arrival: dataX.data[dataX.data.length - 1].arrival,
          isCrossDay: dataX.isCrossDay,
          detailTerminals: [] as any
        }
        dataX.data.forEach((d: any) => {
          const obj = {
            id: d.id ?? null,
            name: d.name ?? null,
            departure: d.departure ?? null,
            arrival: d.arrival ?? null,
            isCrossDay: dataX.isCrossDay ?? false,
            routeDetailId: d.routeDetailId
          }
          object.detailTerminals.push(obj)
        })
        x[dataX.ritOrder] = object
        if (dataX.ppOrder === 0) {
          setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].departure`, dataX.data[dataX.ppOrder]?.departure)
        }
        // setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].departure`, 'xxx')
        // setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].arrival`, 'xxx')
        setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].arrival`, dataX.data[dataX.ppOrder]?.arrival)
        setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].details[${dataX.ritOrder}]`, x)
      }
      setFieldValue(`data[${dataX.busOrder}].pps[${dataX.ppOrder}].isCrossDay`, dataX.isCrossDay)
      // setPpTime(dataX)
      hideScheduleFormAct()
    }

    onMounted(() => {
      isLoading.value = true
      listBusTemp.value = listBus.value
      // console.log('listBusTemp', listBusTemp.value)
      if (route.path.includes('main')) {
        trayekType.value = 0
      } else if (route.path.includes('airport')) {
        trayekType.value = 2
      } else {
        trayekType.value = 1
      }

      if (!route.params.id) {
        tabHeader.value.pop()
      }

      // console.log('step2', store.state.trayek.form.step2.data)
      if (store.state.trayek.form.step2.data.length === 0) {
        isAnyBus.value = false
      } else {
        showForm()
      }
      setInitialValue()
      setDefaultDetails()
      isLoading.value = false
    })

    return {
      tabsChange,
      setCurrVehicleInfo,
      onSubmit,
      prevPage,
      nextPage,
      addForm,
      schemaReserve,
      schemaAirport,
      schema,
      trayekType,
      focus,
      isLoading,
      selectedBus,
      tabHeader,
      activeTab,
      isAnyBus,
      logTrayek,
      logBus,
      initialValue,
      showForm,
      icon,
      moment,
      defaultValue,
      changeBus,
      duplicateBus,
      showDuplicateMessage,
      getTimeOnly,
      checkIsDate,
      getVal,
      step1Form,
      showScheduleForm,
      selectedRit,
      showScheduleFormAct,
      hideScheduleFormAct,
      setScheduleValue,
      defaultDetailValue
    }
  }
})

